import { Button } from '@mui/material';
import {
    BooleanDisplay,
    CardTable, FilledSection, LabelGroup, LabeledValue, ProgressIndicator, useAsyncEffect, usePageMessage, useParams
} from '@tsp-ui/core';
import React, { useCallback, useState } from 'react';

import {
    AdminSettings, Instance, fetchAdminSettings, fetchInstances,
    runTestQuery
} from '../../api';
import Page from '../Page';
import SectionCard from '../components/SectionCard';

import styles from './InstanceDetailsPage.module.scss';


export default function InstanceDetailsPage() {
    const pageMessage = usePageMessage();
    const { orgId, instanceId } = useParams<{ orgId: string, instanceId: string }>();

    const [ loading, setLoading ] = useState(false);
    const [ queryLoading, setQueryLoading ] = useState(false);
    const [ settings, setSettings ] = useState<AdminSettings>();
    const [ instance, setInstance ] = useState<Instance>();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            if (orgId && instanceId) {
                const instances = await fetchInstances(orgId);
                const currentInstance = instances.find(i => i.instanceId === instanceId);
                setInstance(currentInstance);

                const adminSettings = await fetchAdminSettings(currentInstance?.instanceId || '');
                setSettings(adminSettings);
            }
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching instance details', error);
        }

        setLoading(false);
    }, [
        setLoading, orgId, instanceId, setSettings, pageMessage
    ]));

    async function handleTestQuery() {
        setQueryLoading(true);

        try {
            await runTestQuery(instanceId);
            pageMessage.success('Dev Connect credentials are valid');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while testing Dev Connect credentials', error);
        }

        setQueryLoading(false);
    }

    return (
        <Page
            loading={loading}
            className={styles.root}
            header="Instance details"
            headerActions={(
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleTestQuery}
                    disabled={queryLoading}
                >
                    {queryLoading ? (
                        <ProgressIndicator
                            className={styles.progress}
                            size={20}
                        />
                    ) : 'Run Test Query'}
                </Button>
            )}
        >
            <div className={styles.content}>
                <SectionCard
                    header={instanceId}
                    className={styles.card}
                >
                    <LabelGroup>
                        <LabeledValue
                            label="ID:"
                            value={instance?.id}
                            classNames={{ value: styles.labeledValue }}
                        />

                        <LabeledValue
                            label="Client ID:"
                            value={instance?.clientId}
                            classNames={{ value: styles.labeledValue }}
                        />

                        <LabeledValue
                            label="Client secret:"
                            value={instance?.clientSecret}
                            classNames={{ value: styles.labeledValue }}
                        />
                    </LabelGroup>
                </SectionCard>

                <FilledSection
                    header="Persona Configs"
                >
                    <CardTable
                        headers={tableHeaders}
                        className={styles.table}
                        tableClassName={styles.tableWrapper}
                    >
                        {settings?.personaConfigs?.map((config) => (
                            <tr
                                key={config.personaId}
                            >
                                <td>{config.name}</td>

                                <td><BooleanDisplay value={config.isAdmin} /></td>

                                <td><BooleanDisplay value={config.canImportConditions} /></td>

                                <td>{config.programDetailsView}</td>
                            </tr>
                        ))}
                    </CardTable>
                </FilledSection>
            </div>
        </Page>
    );
}

const tableHeaders = [
    'Name',
    'Admin',
    'Can Import Conditions',
    'Program Details View'
];
