import { Button, DialogContent } from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps, TextField, replaceItemById, useForm, usePageMessage, useParams
} from '@tsp-ui/core';
import { useAsyncEffect } from '@tsp-ui/core/utils';
import { useCallback, useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
    Instance, createInstance, fetchInstances, updateInstance
} from '../../api';

import styles from './InstanceManagementDialog.module.scss';
import { OrgMgmtPageContext } from './OrganizationManagementPage';


interface OrganizationMgmtParams {
    orgId: string;
    instanceId?: string;
}

export default function InstanceManagementDialog({ ...props }: RoutedDialogImplProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const { orgId, instanceId } = useParams<OrganizationMgmtParams>();
    const { setInstanceTableKey } = useContext(OrgMgmtPageContext);

    const [ instances, setInstances ] = useState<Instance[]>([]);
    const [ loading, setLoading ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const formMethods = useForm<Instance>();
    const { reset } = formMethods;

    useAsyncEffect(useCallback(async () => {
        if (instanceId) {
            setLoading(true);

            try {
                const instances = await fetchInstances(orgId);
                setInstances(instances);

                reset(instances.find(inst => inst.id === instanceId));
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching instances', error);
            }

            setLoading(false);
        }
    }, [
        pageMessage, orgId, reset, instanceId
    ]));

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setSaveLoading(true);

        try {
            if (instanceId) {
                setInstances(replaceItemById(
                    instances,
                    await updateInstance(orgId, formValues)
                ));
            } else {
                setInstances([
                    ...instances,
                    await createInstance(orgId, formValues)
                ]);
            }

            setInstanceTableKey(key => key + 1);
            navigate('..');

            pageMessage.success(`Instance ${instanceId ? 'updated' : 'created'}`);
        } catch (error) {
            pageMessage.handleApiError(
                `An error occurred while ${instanceId ? 'updating' : 'creating'} the instance`, error
            );
        }

        setSaveLoading(false);
    });

    return (
        <RoutedDialog
            title={`${instanceId ? 'Update' : 'Add'} instance`}
            loading={loading}
            {...props}
        >
            <DialogContent>
                <form
                    id={formId}
                    onSubmit={handleSubmit}
                    className={styles.form}
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <TextField<Instance>
                            name="instanceId"
                            label="Instance ID"
                            required
                            rules={{
                                validate: (value) => (
                                    instances.some(inst => inst.id !== instanceId && inst.instanceId === value)
                                        ? 'Instance ID must be unique' : undefined
                                )
                            }}
                        />

                        <TextField<Instance>
                            name="clientId"
                            label="Client ID"
                            required
                        />

                        <TextField<Instance>
                            name="clientSecret"
                            label="Secret"
                            required
                            multiline
                            className={styles.secret}
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={saveLoading}>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    form={formId}
                    disabled={saveLoading}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

const formId = 'inst-management-form';
