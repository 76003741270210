import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';

import App from './App';


const { worker } = require('./mocks/browser');


declare global {
    interface Window {
        Cypress?: any;
        mocks: {
            isRunning: () => boolean;
            start: () => void;
            stop: () => void;
        };
    }
}

let mocksRunning = false;

window.mocks = {
    isRunning() {
        return mocksRunning;
    },
    start() {
        mocksRunning = true;
        worker.start({
            onUnhandledRequest: 'bypass'
        });
    },
    stop() {
        mocksRunning = false;
        worker.stop();
    }
};

if ((process.env.NODE_ENV === 'development' && !window.Cypress)) {
    window.mocks.start();

    window.addEventListener('focus', () => {
        if (mocksRunning) {
            window.mocks.stop();
            window.mocks.start();
        }
    });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </StrictMode>
);
