import {
    Button, DialogContent
} from '@mui/material';
import {
    DialogActions, PhoneField, RoutedDialog, RoutedDialogImplProps,
    TextField, replaceItemById, usePageMessage, useParams
} from '@tsp-ui/core';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { User, createUser, updateUser } from '../../api';

import styles from './UserManagementDialog.module.scss';
import { UserMgmtPageContext } from './UserManagementPage';


interface UserMgmtParams {
    userId?: string;
}

export default function UserManagementDialog({ ...props }: RoutedDialogImplProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const { users, setUsers } = useContext(UserMgmtPageContext);
    const { userId } = useParams<UserMgmtParams>();

    const [ loading, setLoading ] = useState(false);
    const userToEdit = users.find(u => u.id === userId);

    const formMethods = useForm<User>({ defaultValues: userToEdit });

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            const user = userId
                ? await updateUser(formValues)
                : await createUser(formValues);

            setUsers(userId ? replaceItemById(users, user) : [ ...users, user ]);

            navigate('..');
            pageMessage.success(`User ${userId ? 'updated' : 'created'}`);
        } catch (error) {
            pageMessage.handleApiError(`An error occurred while ${userId ? 'updating' : 'creating'} the user`, error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            title={`${userToEdit ? 'Update' : 'Add'} user`}
            {...props}
        >
            <DialogContent>
                <form
                    id={formId}
                    className={styles.content}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <TextField<User>
                            name="firstName"
                            label="First name"
                            required
                        />

                        <TextField<User>
                            name="lastName"
                            label="Last name"
                            required
                        />

                        <TextField<User>
                            name="email"
                            label="Email"
                            required
                            disabled={!!userToEdit}
                        />

                        <PhoneField<User>
                            name="phone"
                            label="Phone"
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    form={formId}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

const formId = 'user-mgmt-form';
