import { HttpResponse, http } from 'msw';

import { getMockUrl } from '../mocks/getMockURL';

import { DBSecret } from '.';


export const mocks = [
    http.get(getMockUrl('/db-secret'), () => (
        HttpResponse.json(dbsecret, { status: 200 })
    ))
];

const dbsecret: DBSecret = {
    username: 'dpr_reader',
    password: 'password-blah-blah',
    engine: 'postgres',
    host: 'dpr-dev-postgres-db.c1ckq4susx31.us-east-1.rds.amazonaws.com',
    port: 5432,
    dbInstanceIdentifier: 'dpr-dev-postgres-db'
};
