import '@aws-amplify/ui-react/styles.css';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Typography } from '@mui/material';
import { Loader, useRouteState } from '@tsp-ui/core';
import {
    ComponentProps, useEffect, useState
} from 'react';
import '../scss/amplify-theme.scss';
import { Navigate } from 'react-router-dom';

import styles from './LoginPage.module.scss';
import UnauthedHeader from './components/UnauthedHeader';


const components: ComponentProps<typeof Authenticator>['components'] = {
    SignIn: {
        Header() {
            return (
                <Typography
                    variant="h6"
                    className="custom-sign-in-header"
                >
                    Sign in to your account
                </Typography>
            );
        }
    }
};

export default function LogInPage() {
    return (
        <>
            <UnauthedHeader />

            <Authenticator
                className={styles.root}
                components={components}
                loginMechanisms={[ 'email' ]}
                hideSignUp
            >
                {() => (
                    <AuthenticationRedirect />
                )}
            </Authenticator>
        </>
    );
}

interface LoginPageState {
    redirectTo?: string;
}

function AuthenticationRedirect() {
    const [ redirectTo, setRedirectTo ] = useState<string>();
    const { redirectTo: customRedirect } = useRouteState<LoginPageState>();
    const [ loading, setLoading ] = useState(false);

    const isAuthenticated = useAuthenticator().authStatus === 'authenticated';

    useEffect(() => {
        setLoading(true);

        if (isAuthenticated) {
            setRedirectTo(customRedirect || '/users');
        }
        setLoading(false);
    }, [ isAuthenticated, customRedirect ]);

    return redirectTo ? (
        <Navigate to={redirectTo} />
    ) : loading ? (
        <Loader loading />
    ) : null;
}
