import { ContentCopy } from '@mui/icons-material';
import { CopyButton, IconButton, LabeledValue } from '@tsp-ui/core';
import clsx from 'clsx';

import styles from './DBSecretDialog.module.scss';


interface LabeledValueWithCopyButtonProps {
    label: string;
    value?: string | number;
    className?: string | undefined;
}


export default function LabeledValueWithCopyButton({ label, value, className }: LabeledValueWithCopyButtonProps) {
    return (
        <div className={clsx(styles.labelContainer, className)}>
            <LabeledValue
                variant="vertical"
                value={value}
                label={label}
            />

            <CopyButton
                textToCopy={value?.toString() ?? ''}
                successMessage="Copied!"
            >
                {({ onClick }) => (
                    <IconButton
                        onClick={onClick}
                        color="secondary"
                    >
                        <ContentCopy />
                    </IconButton>
                )}
            </CopyButton>
        </div>
    );
}
