import { useAuthenticator } from '@aws-amplify/ui-react';
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';


interface PrivateRouteProps {
    children: ReactNode;
}

export default function PrivateRoute({ children }: PrivateRouteProps) {
    const { authStatus } = useAuthenticator();
    const location = useLocation();

    if (authStatus !== 'authenticated') {
        return (
            <Navigate
                to="/"
                state={{ from: location }}
            />
        );
    }

    return children;
};
