import { SvgIconComponent } from '@mui/icons-material';
import { ListItemButton, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { MouseEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';

import styles from './NavItem.module.scss';


interface NavItemProps {
    to?: string;
    toQueryParam?: string;
    exact?: boolean;
    Icon: SvgIconComponent;
    label: string;
    selected?: boolean;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    dark?: boolean;
    hideIfNoMatch?: boolean;
    suppressBorder?: boolean;
}

export default function NavItem({
    to, toQueryParam, exact, Icon, label, selected, onClick, dark, hideIfNoMatch, suppressBorder
}: NavItemProps) {
    const { pathname } = useLocation();
    const isSelected = selected !== undefined
        ? selected
        : exact
            ? pathname === to
            : pathname.includes(to || '');

    return (hideIfNoMatch && !isSelected) ? null : (
        <ListItemButton
            {...(to && {
                component: Link,
                to: `${to}${toQueryParam || ''}`
            })}
            selected={isSelected}
            classes={{
                selected: clsx(styles.selected, {
                    [styles.dark]: dark,
                    [styles.suppressBorder]: suppressBorder,
                    [styles.subItem]: hideIfNoMatch
                })
            }}
            className={styles.root}
            onClick={onClick}
        >
            <Icon className={styles.icon} />

            <Typography
                variant="caption"
                align="center"
                className={styles.label}
            >
                {label}
            </Typography>
        </ListItemButton>
    );
}
