import { AmplifyProvider, Authenticator } from '@aws-amplify/ui-react';
import {
    CssBaseline,
    StyledEngineProvider, ThemeProvider, Toolbar, Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    ConfirmDialog, ConfirmDialogContext, Loader, PageMessageContext
} from '@tsp-ui/core/components';
import { useAsyncEffect, useConfirmDialogValue, usePageMessageValue } from '@tsp-ui/core/utils';
import { useCallback, useState } from 'react';
import {
    Route, Routes, useLocation
} from 'react-router-dom';

import styles from './App.module.scss';
import configureApp from './config/configureApp';
import { theme } from './config/mui-theme';
import LogInPage from './views/LoginPage';
import DBSecretDialog from './views/components/DBSecretDialog';
import EventResultsDialog from './views/components/EventResultsDialog';
import NavMenu from './views/components/NavMenu';
import PrivateRoute from './views/components/PrivateRoute';
import InstanceDetailsPage from './views/orgs/InstanceDetailsPage';
import OrganizationManagementPage from './views/orgs/OrganizationManagementPage';
import UserManagementPage from './views/users/UserManagementPage';


export default function App() {
    const pageMessageValue = usePageMessageValue();
    const confirmDialogValue = useConfirmDialogValue();
    const location = useLocation();

    const atLoginPage = location.pathname === '/';

    const [ configError, setConfigError ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ showDbCreds, setShowDbCreds ] = useState(false);
    const [ showEventResultsDialog, setShowEventResultsDialog ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            await configureApp();
        } catch (error) {
            setConfigError(true);
            console.error(error);
        }

        setLoading(false);
    }, []));

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <AmplifyProvider>
                        <Authenticator.Provider>
                            <PageMessageContext.Provider value={pageMessageValue}>
                                <ConfirmDialogContext.Provider value={confirmDialogValue}>
                                    <CssBaseline />

                                    <ConfirmDialog />

                                    <div className={styles.root}>
                                        {!atLoginPage && (
                                            <header className={styles.headerContainer}>
                                                <Toolbar className={styles.toolbar}>
                                                    <img
                                                        src="./logo.svg"
                                                        alt="Down Payment Resource Logo"
                                                        className={styles.logo}
                                                    />

                                                    <NavMenu
                                                        onShowDbCredentialsClick={() => setShowDbCreds(true)}
                                                        onShowEventResultsClick={() => setShowEventResultsDialog(true)}
                                                    />
                                                </Toolbar>
                                            </header>
                                        )}

                                        {configError ? (
                                            <Typography align="center">
                                                An error occurred while initializing the service.<br />
                                                Please try again or contact support if this issue persists.
                                            </Typography>
                                        ) : loading ? (
                                            <Loader loading={loading} />
                                        ) : (
                                            <Routes>
                                                <Route
                                                    path="/"
                                                    element={<LogInPage />}
                                                />

                                                <Route
                                                    path="/users/*"
                                                    element={(
                                                        <PrivateRoute>
                                                            <UserManagementPage />
                                                        </PrivateRoute>
                                                    )}
                                                />

                                                <Route
                                                    path="/organizations/*"
                                                    element={(
                                                        <PrivateRoute>
                                                            <Routes>
                                                                <Route
                                                                    path="/*"
                                                                    element={<OrganizationManagementPage />}
                                                                />

                                                                <Route
                                                                    path=":orgId/instance/:instanceId/details"
                                                                    element={<InstanceDetailsPage />}
                                                                />
                                                            </Routes>
                                                        </PrivateRoute>
                                                    )}
                                                />
                                            </Routes>
                                        )}
                                    </div>

                                    <DBSecretDialog
                                        open={!loading && showDbCreds}
                                        onClose={() => setShowDbCreds(false)}
                                    />

                                    <EventResultsDialog
                                        open={!loading && showEventResultsDialog}
                                        onClose={() => setShowEventResultsDialog(false)}
                                    />
                                </ConfirmDialogContext.Provider>
                            </PageMessageContext.Provider>
                        </Authenticator.Provider>
                    </AmplifyProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
