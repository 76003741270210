import { DialogContent } from '@mui/material';
import {
    Dialog, DialogProps, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import { useCallback, useState } from 'react';

import { DBSecret, getDBSecret } from '../../api';

import styles from './DBSecretDialog.module.scss';
import LabeledValueWithCopyButton from './LabeledValueWithCopyButton';


export default function DBSecretDialog({ open, ...props }: Omit<DialogProps, 'title'>) {
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(false);
    const [ dbSecret, setDbSecret ] = useState<DBSecret>();

    useAsyncEffect(useCallback(async () => {
        if (open) {
            setLoading(true);

            try {
                setDbSecret(await getDBSecret());
            } catch (error) {
                pageMessage.handleApiError('An error occurred while fetching secret', error);
            }

            setLoading(false);
        }
    }, [ pageMessage, open ]));

    return (
        <Dialog
            title="DB secret"
            loading={loading}
            open={open}
            {...props}
        >
            <DialogContent className={styles.content}>
                <LabeledValueWithCopyButton
                    label="Username"
                    value={dbSecret?.username}
                />

                <LabeledValueWithCopyButton
                    label="Password"
                    value={dbSecret?.password}
                />

                <LabeledValueWithCopyButton
                    label="Host"
                    value={dbSecret?.host}
                    className={styles.hostLabel}
                />

                <LabeledValueWithCopyButton
                    label="Port"
                    value={dbSecret?.port}
                />

                <LabeledValueWithCopyButton
                    label="Database Name"
                    value={dbSecret?.dbInstanceIdentifier}
                />
            </DialogContent>
        </Dialog>
    );
}

