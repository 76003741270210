import { HttpResponse, http } from 'msw';


import { getMockUrl } from '../mocks/getMockURL';

import { ProgramDetailsView } from './api-utils';

import { AdminSettings } from '.';


export const mocks = [
    http.get(getMockUrl('/admin/settings'), () => (
        HttpResponse.json(adminSettings, { status: 200 })
    ))
];

const adminSettings: AdminSettings = {
    canImportConditions: true,
    instanceUsesEnhancedConditions: true,
    personaConfigs: [
        {
            personaId: '1',
            name: 'System Administrator',
            isAdmin: true,
            canImportConditions: true,
            programDetailsView: ProgramDetailsView.LO
        },
        {
            personaId: '2',
            name: 'Program Manager',
            isAdmin: false,
            canImportConditions: true,
            programDetailsView: ProgramDetailsView.LO
        },
        {
            personaId: '3',
            name: 'Analyst',
            isAdmin: false,
            canImportConditions: false,
            programDetailsView: ProgramDetailsView.LO
        },
        {
            personaId: '4',
            name: 'Viewer',
            isAdmin: false,
            canImportConditions: false,
            programDetailsView: ProgramDetailsView.UW
        }
    ]
};
