import {
    Paper, Typography
} from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import styles from './SectionCard.module.scss';


interface SectionCardProps {
    children: ReactNode;
    header: ReactNode;
    className?: string;
}

export default function SectionCard({
    children, header, className
}: SectionCardProps) {
    return (
        <Paper
            className={clsx(styles.root, className)}
            variant="outlined"
        >
            <Typography
                variant="h6"
                className={styles.title}
            >
                {header}
            </Typography>

            {children}
        </Paper>
    );
}
