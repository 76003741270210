import { HttpResponse, http } from 'msw';

import { Instance, Organization } from '..';
import { getMockUrl } from '../../mocks/getMockURL';


export const mocks = [
    http.get(getMockUrl('/organization'), () => (
        HttpResponse.json(organizations, { status: 200 })
    )),

    http.post(getMockUrl('/organization'), async ({ request }) => {
        const newOrganization = await request.json() as Organization;
        const createdOrganization: Organization = {
            ...newOrganization,
            id: `${organizations.length + 1}`,
            isActive: true,
            numInstances: 0
        };
        organizations.push(createdOrganization);
        return HttpResponse.json(createdOrganization, { status: 201 });
    }),

    http.put(getMockUrl('/organization/:id'), async ({ request, params }) => {
        const { id } = params;
        const updatedOrganization = await request.json() as Organization;
        const index = organizations.findIndex(org => org.id === id);
        if (index !== -1) {
            organizations[index] = updatedOrganization;
            return HttpResponse.json(updatedOrganization, { status: 200 });
        }
        return HttpResponse.json({ message: 'Organization not found' }, { status: 404 });
    }),

    http.post(getMockUrl('/organization/:id/instance'), async ({ request, params }) => {
        const { id } = params;
        const newInstance = await request.json() as Instance;
        const instances = orgInstanceMap[id as string];
        if (instances) {
            instances.push(newInstance);
            return HttpResponse.json(newInstance, { status: 201 });
        }
        return HttpResponse.json({ message: 'Organization not found' }, { status: 404 });
    }),

    http.put(getMockUrl('/organization/:id/instance/:instanceID'), async ({ request, params }) => {
        const { id, instanceID } = params;
        const updatedInstance = await request.json() as Instance;
        const instances = orgInstanceMap[id as string];
        if (instances) {
            const index = instances.findIndex(inst => inst.instanceId === instanceID);
            if (index !== -1) {
                instances[index] = updatedInstance;
                return HttpResponse.json(updatedInstance, { status: 200 });
            }
            return HttpResponse.json({ message: 'Instance not found' }, { status: 404 });
        }
        return HttpResponse.json({ message: 'Organization not found' }, { status: 404 });
    })
];

const orgInstanceMap: Record<string, Instance[]> = {
    1: [
        {
            id: '1',
            instanceId: 'inst1',
            clientSecret: 'secret1',
            clientId: 'client1'
        },
        {
            id: '2',
            instanceId: 'inst2',
            clientSecret: 'secret2',
            clientId: 'client2'
        },
        {
            id: '3',
            instanceId: 'inst3',
            clientSecret: 'secret3',
            clientId: 'client3'
        }
    ],
    2: [
        {
            id: '4',
            instanceId: 'inst4',
            clientSecret: 'secret4',
            clientId: 'client4'
        },
        {
            id: '5',
            instanceId: 'inst5',
            clientSecret: 'secret5',
            clientId: 'client5'
        }
    ],
    3: [
        {
            id: '6',
            instanceId: 'inst6',
            clientSecret: 'secret6',
            clientId: 'client6'
        }
    ],
    4: [
        {
            id: '7',
            instanceId: 'inst7',
            clientSecret: 'secret7',
            clientId: 'client7'
        },
        {
            id: '8',
            instanceId: 'inst8',
            clientSecret: 'secret8',
            clientId: 'client8'
        }
    ],
    5: [
        {
            id: '9',
            instanceId: 'inst9',
            clientSecret: 'secret9',
            clientId: 'client9'
        },
        {
            id: '10',
            instanceId: 'inst10',
            clientSecret: 'secret10',
            clientId: 'client10'
        },
        {
            id: '11',
            instanceId: 'inst11',
            clientSecret: 'secret11',
            clientId: 'client11'
        }
    ],
    6: [
        {
            id: '12',
            instanceId: 'inst12',
            clientSecret: 'secret12',
            clientId: 'client12'
        }
    ],
    7: [
        {
            id: '13',
            instanceId: 'inst13',
            clientSecret: 'secret13',
            clientId: 'client13'
        },
        {
            id: '14',
            instanceId: 'inst14',
            clientSecret: 'secret14',
            clientId: 'client14'
        }
    ],
    8: [
        {
            id: '15',
            instanceId: 'inst15',
            clientSecret: 'secret15',
            clientId: 'client15'
        },
        {
            id: '16',
            instanceId: 'inst16',
            clientSecret: 'secret16',
            clientId: 'client16'
        },
        {
            id: '17',
            instanceId: 'inst17',
            clientSecret: 'secret17',
            clientId: 'client17'
        }
    ]
};

const organizations: Organization[] = [
    {
        id: '1',
        organizationId: 'Acme Corp',
        isActive: true,
        numInstances: orgInstanceMap['1'].length
    },
    {
        id: '2',
        organizationId: 'Globex Corporation',
        isActive: true,
        numInstances: orgInstanceMap['2'].length
    },
    {
        id: '3',
        organizationId: 'Umbrella Inc.',
        isActive: true,
        numInstances: orgInstanceMap['3'].length
    },
    {
        id: '4',
        organizationId: 'Soylent Corporation',
        isActive: true,
        numInstances: orgInstanceMap['4'].length
    },
    {
        id: '5',
        organizationId: 'Initech',
        isActive: true,
        numInstances: orgInstanceMap['5'].length
    },
    {
        id: '6',
        organizationId: 'Hooli',
        isActive: true,
        numInstances: orgInstanceMap['6'].length
    },
    {
        id: '7',
        organizationId: 'Vehement Capital Partners',
        isActive: true,
        numInstances: orgInstanceMap['7'].length
    },
    {
        id: '8',
        organizationId: 'Wonka Industries',
        isActive: true,
        numInstances: orgInstanceMap['8'].length
    }
];
