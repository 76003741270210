import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import {
    CardTable, IconButton, PhoneTypography, RoutedDialogManager, useAsyncEffect, useConfirm,
    usePageMessage
} from '@tsp-ui/core';
import {
    Dispatch, SetStateAction, createContext, useCallback, useState
} from 'react';
import { Link } from 'react-router-dom';

import { User, deleteUser, getUsers } from '../../api';
import Page from '../Page';

import UserManagementDialog from './UserManagementDialog';
import styles from './UserManagementPage.module.scss';


interface UserMgmtPageContextValue {
    users: User[];
    setUsers: Dispatch<SetStateAction<User[]>>;
}

export const UserMgmtPageContext = createContext<UserMgmtPageContextValue>({
    users: [],
    setUsers: () => {}
});

export default function UserManagementPage() {
    const [ users, setUsers ] = useState<User[]>([]);
    const [ loading, setLoading ] = useState(false);
    const [ deletedUserEmail, setDeletedUserEmail ] = useState<string | null>(null);

    const pageMessage = usePageMessage();
    const confirm = useConfirm();

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setUsers(await getUsers());
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching users', error);
        }

        setLoading(false);
    }, [ setUsers, pageMessage ]));

    async function handleDelete(email: string) {
        setDeletedUserEmail(email);

        if (await confirm('Are you sure you want to delete this user?')) {
            try {
                await deleteUser(email);

                setUsers(prevUsers => prevUsers.filter(user => user.email !== email));

                pageMessage.success('User deleted');
            } catch (error) {
                pageMessage.handleApiError('An error occurred while deleting the user', error);
            }
        }

        setDeletedUserEmail(null);
    }

    return (
        <UserMgmtPageContext.Provider value={{
            users,
            setUsers
        }}
        >
            <Page
                loading={loading}
                className={styles.container}
                header="Users"
                headerActions={(
                    <Button
                        component={Link}
                        to="add"
                        color="secondary"
                        variant="contained"
                    >
                        Add User
                    </Button>
                )}
            >
                <CardTable
                    headers={tableHeaders}
                    className={styles.table}
                >
                    {users?.map((user) => (
                        <tr key={user.id}>
                            <Typography component="td">{user.firstName || '--'}</Typography>

                            <Typography component="td">{user.lastName || '--'}</Typography>

                            <Typography component="td">{user.email || '--'}</Typography>

                            {user.phone ? (
                                <PhoneTypography component="td">{user.phone}</PhoneTypography>
                            ) : (
                                <Typography component="td">--</Typography>
                            )}

                            <td className={styles.iconButtonsContainer}>
                                <IconButton
                                    component={Link}
                                    to={`${user.id}/edit`}
                                    tooltip="Edit"
                                    color="secondary"
                                >
                                    <Edit />
                                </IconButton>

                                <IconButton
                                    tooltip="Delete"
                                    color="error"
                                    loading={deletedUserEmail === user.email}
                                    onClick={() => handleDelete(user.email)}
                                >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                </CardTable>

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </UserMgmtPageContext.Provider>
    );
}

const tableHeaders = [
    'First Name',
    'Last Name',
    'Email',
    'Phone',
    ''
];

const dialogRoutes = {
    add: UserManagementDialog,
    ':userId/edit': UserManagementDialog
};
