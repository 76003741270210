import {
    AdminPanelSettings, Checklist, ManageAccounts, Password,
    Report
} from '@mui/icons-material';
import { Button } from '@mui/material';
import { IconButton, usePageMessage } from '@tsp-ui/core';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


import { updateProductConditions } from '../../api';

import NavItem from './NavItem';
import styles from './NavMenu.module.scss';


interface NavMenuProps {
    onShowDbCredentialsClick: () => void;
    onShowEventResultsClick: () => void;
}

export default function NavMenu({ onShowDbCredentialsClick, onShowEventResultsClick }: NavMenuProps) {
    const navigate = useNavigate();
    const pageMessage = usePageMessage();

    const [ refreshLoading, setRefreshLoading ] = useState(false);

    async function handleRefreshProductConditions() {
        setRefreshLoading(true);

        try {
            await updateProductConditions(null);
            pageMessage.success('Product conditions refreshed');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while refreshing product conditions', error);
        }

        setRefreshLoading(false);
    }

    async function handleSignOut() {
        try {
            await Auth.signOut();
            navigate('/');
        } catch (error) {
            console.error('Error signing out:', error);
        }
    }

    return (
        <>
            <nav className={styles.nav}>
                <NavItem
                    to="users"
                    label="Users"
                    Icon={ManageAccounts}
                />

                <NavItem
                    to="/organizations"
                    label="Organizations"
                    Icon={AdminPanelSettings}
                />
            </nav>

            <div>
                <IconButton
                    tooltip="Refresh product conditions"
                    color="primary"
                    loading={refreshLoading}
                    onClick={handleRefreshProductConditions}
                >
                    <Checklist />
                </IconButton>

                <IconButton
                    tooltip="View read-only credentials"
                    color="primary"
                    onClick={onShowDbCredentialsClick}
                >
                    <Password />
                </IconButton>

                <IconButton
                    tooltip="View event results"
                    color="primary"
                    onClick={onShowEventResultsClick}
                >
                    <Report />
                </IconButton>

                <Button
                    onClick={handleSignOut}
                    variant="outlined"
                    className={styles.logOut}
                >
                    Log out
                </Button>
            </div>
        </>
    );
}
