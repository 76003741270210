import { HttpResponse, http } from 'msw';

import { User } from '..';
import { getMockUrl } from '../../mocks/getMockURL';


export const mocks = [
    http.get(getMockUrl('/user'), () => (
        HttpResponse.json(users, { status: 200 })
    )),

    http.post(getMockUrl('/user'), async ({ request }) => {
        const newUser = await request.json() as User;
        const createdUser: User = {
            ...newUser,
            id: `${users.length + 1}`
        };
        users.push(createdUser);
        return HttpResponse.json(createdUser, { status: 201 });
    }),

    http.put(getMockUrl('/user/:id'), async ({ request, params }) => {
        const { id } = params;
        const updatedUser = await request.json() as User;
        const index = users.findIndex(user => user.id === id);
        if (index !== -1) {
            users[index] = updatedUser;
            return HttpResponse.json(updatedUser, { status: 200 });
        }
        return HttpResponse.json({ message: 'User not found' }, { status: 404 });
    }),

    http.delete(getMockUrl('/user/:id'), ({ params }) => {
        const { id } = params;
        const index = users.findIndex(user => user.id === id);
        if (index !== -1) {
            const deactivatedUser = users.splice(index, 1);
            return HttpResponse.json(deactivatedUser, { status: 200 });
        }
        return HttpResponse.json({ message: 'User not found' }, { status: 404 });
    })
];

const users: User[] = [
    {
        id: '1',
        email: 'johndoe@email.com',
        firstName: 'John',
        lastName: 'Doe',
        phone: '555-555-5555'
    },
    {
        id: '2',
        email: 'janedoe@email.com',
        firstName: 'Jane',
        lastName: 'Doe',
        phone: '555-555-5556'
    },
    {
        id: '3',
        email: 'bobsmith@email.com',
        firstName: 'Bob',
        lastName: 'Smith',
        phone: '555-555-5557'
    },
    {
        id: '4',
        email: 'alicesmith@email.com',
        firstName: 'Alice',
        lastName: 'Smith',
        phone: '555-555-5558'
    },
    {
        id: '5',
        email: 'charliebrown@email.com',
        firstName: 'Charlie',
        lastName: 'Brown',
        phone: '555-555-5559'
    },
    {
        id: '6',
        email: 'davidjones@email.com',
        firstName: 'David',
        lastName: 'Jones',
        phone: '555-555-5560'
    },
    {
        id: '7',
        email: 'emilywhite@email.com',
        firstName: 'Emily',
        lastName: 'White',
        phone: '555-555-5561'
    },
    {
        id: '8',
        email: 'frankjohnson@email.com',
        firstName: 'Frank',
        lastName: 'Johnson',
        phone: '555-555-5562'
    }
];
