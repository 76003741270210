import { Edit } from '@mui/icons-material';
import {
    CardTable, IconButton, SubtleLink, usePageMessage
} from '@tsp-ui/core';
import { Loader } from '@tsp-ui/core/components';
import { useAsyncEffect } from '@tsp-ui/core/utils';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { Instance, fetchInstances } from '../../../api';
import styles from '../OrganizationManagementPage.module.scss';


interface OrgInstanceTableProps {
    orgId: string;
}

export function OrgInstanceTable({ orgId }: OrgInstanceTableProps) {
    const pageMessage = usePageMessage();

    const [ instances, setInstances ] = useState<Instance[]>();
    const [ loading, setLoading ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setInstances(await fetchInstances(orgId));
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching instances', error);
        }

        setLoading(false);
    }, [ pageMessage, orgId ]));

    return loading ? (
        <Loader
            className={styles.loader}
            loading
        />
    ) : (
        <CardTable
            headers={tableHeaders}
            className={styles.table}
        >
            {instances?.map((instance) => (
                <tr
                    key={instance.instanceId}
                >
                    <td>
                        <SubtleLink to={`${orgId}/instance/${instance.instanceId}/details`}>
                            {instance.instanceId}
                        </SubtleLink>
                    </td>

                    <td>
                        {instance.clientId}
                    </td>

                    <td className={styles.buttonCell}>
                        <IconButton
                            component={Link}
                            to={`${orgId}/instance/${instance.id}/edit`}
                            tooltip="Edit"
                            color="secondary"
                        >
                            <Edit />
                        </IconButton>
                    </td>
                </tr>
            ))}
        </CardTable>
    );
}

const tableHeaders = [
    'Instance ID',
    'Client ID',
    ''
];
