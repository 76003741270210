import {
    AddCircleOutline, CheckCircleOutline, RemoveCircleOutline
} from '@mui/icons-material';
import {
    Button, Checkbox, FormControlLabel, Paper, Typography
} from '@mui/material';
import {
    ExpandableHeader, FilterTextField, IconButton, RoutedDialogManager,
    replaceItemById, useAsyncEffect, useConfirm, usePageMessage
} from '@tsp-ui/core';
import {
    Dispatch, SetStateAction, createContext, useCallback, useState
} from 'react';
import { Link } from 'react-router-dom';

import {
    Organization, getOrganizations, updateOrganization
} from '../../api';
import Page from '../Page';

import InstanceManagementDialog from './InstanceManagementDialog';
import OrganizationManagementDialog from './OrganizationManagementDialog';
import styles from './OrganizationManagementPage.module.scss';
import { OrgInstanceTable } from './components/OrgInstanceTable';


interface OrgMgmtPageContextValue {
    organizations: Organization[];
    setOrganizations: Dispatch<SetStateAction<Organization[]>>;
    setInstanceTableKey: Dispatch<SetStateAction<number>>;
}

export const OrgMgmtPageContext = createContext<OrgMgmtPageContextValue>({
    organizations: [],
    setOrganizations: () => {},
    setInstanceTableKey: () => {}
});

export default function OrganizationManagementPage() {
    const pageMessage = usePageMessage();

    const [ organizations, setOrganizations ] = useState<Organization[]>([]);
    const [ instanceTableKey, setInstanceTableKey ] = useState(0);
    const [ loading, setLoading ] = useState(false);
    const [ showInactive, setShowInactive ] = useState(false);
    const [ search, setSearch ] = useState('');

    function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearch(event.target.value);
    }

    function toggleInactive() {
        setShowInactive(!showInactive);
    }

    useAsyncEffect(useCallback(async () => {
        setLoading(true);

        try {
            setOrganizations(await getOrganizations());
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching organizations', error);
        }

        setLoading(false);
    }, [ setOrganizations, pageMessage ]));

    const sortedOrgs = organizations.sort((a, b) => a.organizationId.localeCompare(b.organizationId));
    const filteredOrgs = sortedOrgs.filter(
        org => (showInactive || org.isActive) && org.organizationId.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <OrgMgmtPageContext.Provider
            value={{
                organizations,
                setOrganizations,
                setInstanceTableKey
            }}
        >
            <Page
                loading={loading}
                className={styles.root}
                header="Organizations"
                headerActions={(
                    <>
                        <FilterTextField
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            helperText="Filter by org id"
                        />

                        <FormControlLabel
                            control={(
                                <Checkbox
                                    checked={showInactive}
                                    onClick={toggleInactive}
                                />
                            )}
                            label="Include deactivated"
                        />

                        <Button
                            component={Link}
                            to="add"
                            color="secondary"
                            variant="contained"
                        >
                            Add Organization
                        </Button>
                    </>
                )}
            >
                <div className={styles.container}>
                    {filteredOrgs?.map((org) => (
                        <OrgHeader
                            key={org.id}
                            organization={org}
                            instanceTableKey={instanceTableKey}
                            setOrganizations={setOrganizations}
                        />
                    ))}
                </div>

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </OrgMgmtPageContext.Provider>
    );
}

const dialogRoutes = {
    add: OrganizationManagementDialog,
    ':orgId/instance/add': InstanceManagementDialog,
    ':orgId/instance/:instanceId/edit': InstanceManagementDialog
};

interface OrgHeaderProps {
    organization: Organization;
    instanceTableKey: number;
    setOrganizations: Dispatch<SetStateAction<Organization[]>>;
}

function OrgHeader({ organization, instanceTableKey, setOrganizations }: OrgHeaderProps) {
    const pageMessage = usePageMessage();
    const confirm = useConfirm();
    const [ deactivateLoading, setDeactivateLoading ] = useState(false);

    const { isActive, organizationId } = organization;

    async function handleStatusChange() {
        setDeactivateLoading(true);

        if (await confirm(
            `Are you sure you want to ${isActive ?  'deactivate' : 'activate'} ${organizationId}?`
        )) {
            try {
                const updatedOrg = await updateOrganization({
                    ...organization,
                    isActive: !organization.isActive
                });

                setOrganizations(organizations => replaceItemById(organizations, updatedOrg));

                pageMessage.success(`Organization ${isActive ?  'deactivated' : 'activated'}`);
            } catch (error) {
                pageMessage.handleApiError(
                    `An error occurred while ${isActive ?  'deactivating' : 'activating'} the organization`, error
                );
            }
        }

        setDeactivateLoading(false);
    }

    return (
        <div>
            <ExpandableHeader
                title={organization.organizationId}
                defaultExpand={false}
                disableExpand={false}
                className={styles.expandableHeader}
                secondaryText={(
                    <>
                        <IconButton
                            component={Link}
                            to={`${organization.id}/instance/add`}
                            tooltip="Add Instance"
                            color="secondary"
                        >
                            <AddCircleOutline />
                        </IconButton>

                        {organization.isActive ? (
                            <IconButton
                                tooltip="Deactivate"
                                color="error"
                                onClick={handleStatusChange}
                                loading={deactivateLoading}
                            >
                                <RemoveCircleOutline />
                            </IconButton>
                        ) : (
                            <IconButton
                                tooltip="Activate"
                                color="success"
                                onClick={handleStatusChange}
                                loading={deactivateLoading}
                            >
                                <CheckCircleOutline />
                            </IconButton>
                        )}
                    </>
                )}
                expandedContent={organization.numInstances === 0 ? (
                    <Paper
                        className={styles.noResults}
                        variant="outlined"
                    >
                        <Typography align="center">
                            This organization has no instances.
                        </Typography>
                    </Paper>
                ) : (
                    <OrgInstanceTable
                        key={instanceTableKey}
                        orgId={organization.id}
                    />
                )}
            />
        </div>
    );
}
