import { Button, DialogContent } from '@mui/material';
import {
    DialogActions, RoutedDialog, RoutedDialogImplProps, TextField, useForm, usePageMessage
} from '@tsp-ui/core';
import { useContext, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Organization, createOrganization } from '../../api';

import { OrgMgmtPageContext } from './OrganizationManagementPage';


export default function OrganizationManagementDialog({ ...props }: RoutedDialogImplProps) {
    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const { organizations, setOrganizations } = useContext(OrgMgmtPageContext);

    const [ loading, setLoading ] = useState(false);

    const formMethods = useForm<Organization>();

    const handleSubmit = formMethods.handleSubmit(async (formValues) => {
        setLoading(true);

        try {
            setOrganizations([
                ...organizations, await createOrganization({
                    ...formValues,
                    isActive: true
                })
            ]);

            navigate('..');

            pageMessage.success('Organization added');
        } catch (error) {
            pageMessage.handleApiError('An error occurred while adding the organization', error);
        }

        setLoading(false);
    });

    return (
        <RoutedDialog
            title="Add Organization"
            {...props}
        >
            <DialogContent>
                <form
                    id={formId}
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <TextField<Organization>
                            name="organizationId"
                            label="Organization ID"
                            required
                            rules={{
                                validate: (value) => (
                                    organizations.some(org => org.organizationId === value)
                                        ? 'Organization ID must be unique' : undefined
                                )
                            }}
                        />
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions loading={loading}>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    form={formId}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

const formId = 'org-management-form';
